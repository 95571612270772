import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const AdminRoute = () => {
    const { currentUser, currentXpediteUser } = useAuth();
    if (!currentUser || currentXpediteUser.role !== 'admin') {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
};

export default AdminRoute;
