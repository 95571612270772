import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../../context/AuthContext';
import AddUser from './AddUser';
import UserList from './UserList';
import '../../variables.css';
import api from '../../api';
import logger from "../../logger";

Modal.setAppElement('#root'); // Ensure this is set for accessibility

export default function AdminPage() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [confirmationAction, setConfirmationAction] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [jobID, setJobID] = useState("");
    const [toDate, setToDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [timeoutSAM, setTimeoutSAM] = useState(false);
    const { currentXpediteUser } = useAuth();

    useEffect(() => {
        async function fetchUsers() {
            try {
                const response = await api.get(`/users`);
                setUsers(response.data);
            } catch (error) {
                logger.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        }

        if (currentXpediteUser.role === 'admin') {
            fetchUsers();
        } else {
            setLoading(false);
        }
    }, [currentXpediteUser]);

    async function handleRoleChange(userId, newRole) {
        try {
            await api.put(`/users/${userId}`, { role: newRole });
            setUsers(users.map(user => (user.id === userId ? { ...user, role: newRole } : user)));
        } catch (error) {
            logger.error('Error updating user role:', error);
        }
    }

    async function handleDelete(userId) {
        try {
            await api.delete(`/users/${userId}`);
            setUsers(users.filter(user => user.id !== userId));
        } catch (error) {
            logger.error('Error deleting user:', error);
        }
    }

    async function handleRefreshSAM() {
        try {
            setTimeoutSAM(true)
            // set dates to today in MM/DD/YYYY format
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let dateFormat = today.getMonth() + 1 + '/' + dd + '/' + today.getFullYear()
            setFromDate(dateFormat);
            setToDate(dateFormat)
            let dataType = "sam"
            let data = {
                "fromDate": dateFormat,
                "toDate": dateFormat,
                "dataType": dataType
            }
            logger.info(`datapull:sam:payload - ${data}`)
            let response = await api.post(`/datapull`, data);
            if (response.data.jobID) {
                setJobID(response.data.jobID);
                setTimeout(() => {
                    setTimeoutSAM(false)
                }, 3000);
            } else {
                setTimeoutSAM(false)
                logger.error('Error refreshing SAM.gov:', response.data);
            }
        } catch (error) {
            setTimeoutSAM(false)
            logger.error('Error refreshing SAM.gov:', error);
        }
    }

    const openAddUserModal = () => {
        setIsAddUserModalOpen(true);
    };

    const closeAddUserModal = () => {
        setIsAddUserModalOpen(false);
    };

    const openConfirmModal = (action, message) => {
        setConfirmationAction(() => action); // Store the action to be confirmed
        setConfirmationMessage(message); // Set the message for the modal
        setIsConfirmModalOpen(true);
    };

    const closeConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setConfirmationAction(null);
        setConfirmationMessage('');
    };

    const handleConfirmAction = () => {
        if (confirmationAction) {
            confirmationAction(); // Execute the stored action
        }
        closeConfirmModal();
    };

    const confirmRoleChange = (userId, newRole) => {
        openConfirmModal(
            () => handleRoleChange(userId, newRole),
            `Are you sure you want to change this user's role to ${newRole}?`
        );
    };

    const confirmDelete = (userId) => {
        openConfirmModal(
            () => handleDelete(userId),
            'Are you sure you want to delete this user?'
        );
    };



    if (currentXpediteUser.role !== 'admin') {
        return <div>Access denied. Admins only.</div>;
    }

    return (
        <div className="admin-container">
            <h1>Admin Page</h1>
            <button onClick={openAddUserModal}>Add User</button>
            <button onClick={handleRefreshSAM} disabled={timeoutSAM}>Refresh SAM.gov</button>
            <label>{jobID ? "JobID: " + jobID : ""}</label>
            <Modal
                isOpen={isAddUserModalOpen}
                onRequestClose={closeAddUserModal}
                contentLabel="Add User"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <AddUser users={users} setUsers={setUsers} closeModal={closeAddUserModal}/>
            </Modal>
            {loading ? (
                <div className="loading">Loading</div>
            ) : (
                <UserList
                    users={users}
                    handleRoleChange={confirmRoleChange}
                    handleDelete={confirmDelete}
                />
            )}
            <Modal
                isOpen={isConfirmModalOpen}
                onRequestClose={closeConfirmModal}
                contentLabel="Confirm Action"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <h2>Are you sure?</h2>
                <p>{confirmationMessage}</p>
                <button onClick={handleConfirmAction}>Yes</button>
                <button onClick={closeConfirmModal}>No</button>
            </Modal>
        </div>
    );
}
