import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import { useAuth } from '../../context/AuthContext';
import api from '../../api';
import './CustomerProfile.css';
import logger from "../../logger";

export default function CustomerProfile() {
    const { currentXpediteUser } = useAuth();
    const [profile, setProfile] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [jobScheduled, setJobScheduled] = useState('');

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await api.get('/customers');
                setCustomers(response.data);
            } catch (error) {
                logger.error('Error fetching customers', error);
                setError('Failed to load customers');
            }
        };

        if (currentXpediteUser.role === 'admin') {
            fetchCustomers();
        }
    }, [currentXpediteUser.role]);

    useEffect(() => {
        if (selectedCustomer || currentXpediteUser.role !== 'admin') {
            const fetchProfile = async () => {
                try {
                    const customerID = selectedCustomer || currentXpediteUser.customerID;
                    const response = await api.get(`/customers/${customerID}/profile`);
                    if(response.data.Text || response.status === 200)
                        setProfile(response.data.Text);
                    else
                        setProfile("No profile available. Upload a profile document to get started.");
                } catch (error) {
                    logger.error('Error fetching profile', error);
                    setError('Failed to load profile');
                    setProfile("No profile available. Upload a profile document to get started.");
                }
            };

            fetchProfile();
        }
    }, [selectedCustomer, currentXpediteUser.customerID, currentXpediteUser.role]);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        try {
            const customerID = selectedCustomer || currentXpediteUser.customerID;
            const response = await api.post(`/customers/${customerID}/upload-profile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setProfile(response.data.markdown);
        } catch (error) {
            logger.error('Error uploading profile', error);
            setError('Failed to upload profile');
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        try {
            const customerID = selectedCustomer || currentXpediteUser.customerID;
            setIsEditing(false);
            await api.put(`/customers/${customerID}/profile`, { "profile": profile });
        } catch (error) {
            logger.error('Error saving profile', error);
            setError('Failed to save profile');
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    const handleProfileChange = (event) => {
        setProfile(event.target.value);
    };

    const handleCustomerChange = (event) => {
        setSelectedCustomer(event.target.value);
        setCustomerName(event.target.options[event.target.selectedIndex].text);
    };

    const handleScoreProfile = async () => {
        try {
            const customerID = selectedCustomer || currentXpediteUser.customerID;
            const response = await api.post(`/customers/${customerID}/score-profile`);
            if(response.status === 200)
                setJobScheduled(response.data.jobID);
            else
                setJobScheduled('Failed to schedule job');

        } catch (error) {
            logger.error('Error scoring profile', error);
            setError('Failed to score profile');
            setJobScheduled('Failed to schedule job');
        }
    }

    return (
        <div className="profile-container">
            <h2>Customer Profile: {customerName || currentXpediteUser.company}</h2>
            {error && <p className="error">{error}</p>}
            {currentXpediteUser.role === 'admin' && (
                <div className="admin-features">
                    <div className="customer-select">
                        <label htmlFor="customer-select">Select Customer:</label>
                        <select id="customer-select" value={selectedCustomer} onChange={handleCustomerChange}>
                            <option value="">Select a customer</option>
                            {customers.map((customer) => (
                                <option key={customer.id} value={customer.id}>
                                    {customer.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <button onClick={handleScoreProfile}>Score Profile</button>
                        {jobScheduled && <p className="success">Job status: {jobScheduled}</p>}
                    </div>
                </div>
            )}
            {currentXpediteUser.role === 'admin' && (
                <div className="file-upload">
                <label htmlFor="file-upload" className="custom-file-upload">
                        Upload Profile
                    </label>
                    <input id="file-upload" type="file" onChange={handleFileUpload}/>
                </div>
            )}
            {isEditing ? (
                <div className="editor-container">
                    <textarea value={profile || ''} onChange={handleProfileChange} />
                    <div className="button-group">
                        <button onClick={handleSaveClick}>Save</button>
                        <button onClick={handleCancelClick}>Cancel</button>
                    </div>
                </div>
            ) : (
                <div className="profile-content">
                    {profile ? (
                        <Markdown>{profile}</Markdown>
                    ) : (
                        <p>No profile available. Upload a profile document to get started.</p>
                    )}
                    {currentXpediteUser.role === 'admin' && (
                        <button onClick={handleEditClick}>Edit Profile</button>
                    )}
                </div>
            )}
        </div>
    );
}
