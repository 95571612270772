import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export default function ReasonModal({ isOpen, onRequestClose, onSubmit, status, reasonsArray }) {
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [customReason, setCustomReason] = useState('');

    const handleReasonChange = (reason) => {
        if (selectedReasons.includes(reason)) {
            setSelectedReasons(selectedReasons.filter((r) => r !== reason));
        } else {
            setSelectedReasons([...selectedReasons, reason]);
        }
    };

    const handleCustomReasonChange = (event) => {
        setCustomReason(event.target.value);
    };

    const handleSubmit = () => {
        if (selectedReasons) {
            if(customReason) {
                selectedReasons.push(customReason);
            }
            onSubmit(status, selectedReasons.filter((r) => r !== 'Other'));
        }
    };

    function decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Provide a Reason"
            className="modal"
            overlayClassName="modal-overlay"
        >
            <h2>{`Provide a Reason for ${status}`}</h2>
            <div className="reason-checkboxes">
                {reasonsArray.map((reason, index) => (
                    <div key={index}>
                        <label className="filter-checkbox">
                            <input
                                type="checkbox"
                                checked={selectedReasons.includes(reason)}
                                onChange={() => handleReasonChange(reason)}
                            />
                            <span className="custom-checkbox"></span>
                            <span className="checkbox-label">{decodeHtml(reason)}</span>
                        </label>
                    </div>
                ))}
                <div>
                    <label className="filter-checkbox">
                        <input
                            type="checkbox"
                            checked={selectedReasons.includes('Other')}
                            onChange={() => handleReasonChange('Other')}
                        />
                        <span className="custom-checkbox"></span>
                        <span className="checkbox-label">Other</span>
                    </label>
                    {selectedReasons.includes('Other') && (
                        <textarea
                            value={customReason}
                            onChange={handleCustomReasonChange}
                            placeholder="Enter your custom reason"
                        />
                    )}
                </div>
            </div>
            <button onClick={handleSubmit}>Submit</button>
            <button onClick={onRequestClose}>Cancel</button>
        </Modal>
    );
}
