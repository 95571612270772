import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import Home from './components/customer/Home';
import Login from './components/authentication/Login';
import AdminPage from './components/admin/AdminPage';
import PrivateRoute from './routes/PrivateRoute';
import Customer from './components/admin/Customer';
import CustomerList from './components/admin/CustomerList';
import AdminRoute from './routes/AdminRoute';
import { useAuth } from './context/AuthContext';
import './variables.css';
import TopicList from "./components/shared/TopicList";
import CustomerProfilePage from "./components/customer/CustomerProfilePage";
import ChangePassword from './components/authentication/ChangePassword';
import Modal from 'react-modal';
import PasswordReset from "./components/authentication/PasswordResetRequestForm";
import ResetPassword from "./components/authentication/PasswordResetForm";
import PriorArt from "./components/customer/PriorArt";
import JobsList from "./components/admin/Jobs";
import Avatar from "./components/shared/Avatar";
import SAMOpportunitiesList from "./components/shared/SAMOpportunitiesList";

function usePageTracking() {
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', 'G-418ERQL8C9', {
                page_path: location.pathname,
            });
        }
    }, [location]);
}

function App() {
    usePageTracking();
    const { currentUser, currentXpediteUser, logout } = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const menuRef = useRef(null);
    const dropdownRef = useRef(null);
    const changePasswordModalRef = useRef(null);

    const toggleMenu = () => {
        if(currentUser) {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const openChangePasswordModal = () => {
        setIsChangePasswordModalOpen(true);
        setIsDropdownOpen(false);
    };

    const onLogout = () => {
        setIsDropdownOpen(false);
        logout();
    }

    const closeChangePasswordModal = () => {
        setIsChangePasswordModalOpen(false);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }

        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }

        if (changePasswordModalRef.current && !changePasswordModalRef.current.contains(event.target)) {
            setIsChangePasswordModalOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <header className="header">
                <div className="header-left">
                    <button className="menu-toggle" onClick={toggleMenu}>
                        ☰
                    </button>
                </div>
                <div className="app-title-header"><img src="/Xpedite-XLogo-White-XS-Transparent.png"/></div>
                <div className="header-right">
                    {currentUser && currentXpediteUser ? (
                        <button onClick={toggleDropdown} style={{cursor: 'pointer'}}>
                            <Avatar name={currentXpediteUser.name}/>
                        </button>
                    ) : (
                        <div>Not logged in</div>
                    )}
                </div>
                <nav ref={dropdownRef}>
                    {isDropdownOpen && (
                        <ul className="dropdown-menu">
                            <li>
                                <button onClick={openChangePasswordModal}>Change Password</button>
                            </li>
                            <li>
                                <button onClick={onLogout}>Logout</button>
                            </li>
                        </ul>
                    )}
                </nav>
                <nav ref={menuRef}>
                    {isMenuOpen && (
                        <ul className="menu">
                            <li><Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link></li>
                            {currentXpediteUser.role === 'admin' &&
                                <li><Link to="/admin" onClick={() => setIsMenuOpen(false)}>Admin</Link></li>}
                            {currentXpediteUser.role === 'admin' &&
                                <li><Link to="/customers" onClick={() => setIsMenuOpen(false)}>Customers</Link></li>}
                            {currentXpediteUser.role !== 'none' &&
                                <li><Link to="/sbatopics" onClick={() => setIsMenuOpen(false)}>SBIR/STTR Topics</Link></li>}
                            {currentXpediteUser.role !== 'none' &&
                                <li><Link to="/samtopics" onClick={() => setIsMenuOpen(false)}>SAM Topics</Link></li>}
                            {currentXpediteUser.role !== 'none' &&
                                <li><Link to="/profile" onClick={() => setIsMenuOpen(false)}>Profile</Link></li>}
                            {currentXpediteUser.role !== 'none' &&
                                <li><Link to="/past-awards" onClick={() => setIsMenuOpen(false)}>Past Awards</Link>
                                </li>}
                        </ul>
                    )}
                </nav>
            </header>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/password-reset" element={<PasswordReset/>}/>
                <Route path="/password-reset/:token" element={<ResetPassword/>}/>
                <Route element={<PrivateRoute/>}>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/sbatopics" element={<TopicList/>}/>
                    <Route path="/samtopics" element={<SAMOpportunitiesList/>}/>
                    <Route path="/profile" element={<CustomerProfilePage/>}/>
                    <Route path="/past-awards" element={<PriorArt/>}/>
                </Route>
                <Route element={<AdminRoute/>}>
                    <Route path="/admin" element={<AdminPage/>}/>
                    <Route path="/customer/:id" element={<Customer/>}/>
                    <Route path="/customers" element={<CustomerList/>}/>
                    <Route path="/jobs" element={<JobsList/>}/>
                </Route>
            </Routes>
            <Modal
                isOpen={isChangePasswordModalOpen}
                onRequestClose={closeChangePasswordModal}
                contentLabel="Change Password"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <div ref={changePasswordModalRef}>
                    <ChangePassword/>
                </div>
            </Modal>
        </div>
    );
}

export default App;
