// src/components/ChangePassword.js
import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import api from '../../api'; // Assuming you have an api instance setup for axios

Modal.setAppElement('#root'); // Make sure this is set for accessibility

export default function ChangePassword() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordChange, setPasswordChange] = useState({
        'oldPassword': '',
        'newPassword': ''
    });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setError('');
        setSuccess('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('')
        if (passwordChange.newPassword !== confirmPassword) {
            setError('New password and confirm password do not match');
            return;
        }
        try {
            const response = await api.post('/change-password', passwordChange);
            setSuccess('Password updated successfully');
            setTimeout(() => {
                closeModal();
            }, 2000); // Close the modal after 2 seconds
        } catch (error) {
            setError('Failed to update password');
        }
    };

    return (
        <div>
            <div className="form-container">
                <h2>Change Password</h2>
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Old Password</label>
                        <input
                            type="password"
                            value={passwordChange.oldPassword}
                            onChange={(e) =>
                                setPasswordChange({ ...passwordChange, oldPassword: e.target.value })}
                            required
                        />
                    </div>
                    <div>
                        <label>New Password</label>
                        <input
                            type="password"
                            value={passwordChange.newPassword}
                            onChange={(e) =>
                                setPasswordChange({ ...passwordChange, newPassword: e.target.value })}
                            required
                        />
                    </div>
                    <div>
                        <label>Confirm New Password</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Change Password</button>
                </form>
            </div>
        </div>
    );
}
