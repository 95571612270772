import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './admin.css';
import config from '../../config';
import api from '../../api';
import logger from "../../logger";

export default function AddUser({ users, setUsers, closeModal }) {
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        password: 'temporaryPassword', // Set a default temporary password
        role: 'standard',
        company: '',
        phoneNumber: '',
        customerID: ''
    });
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        async function fetchCustomers() {
            try {
                const response = await api.get(`/customers`);
                setCustomers(response.data);
            } catch (error) {
                logger.error('Error fetching customer:', error);
            }
        }

        fetchCustomers();
    }, []);

    async function handleAddUser(e) {
        e.preventDefault();
        try {
            const response = await api.post(`/users`, newUser);
            const firebaseUID = response.data.uid; // Get the Firebase UID from the response
            setUsers([...users, { ...newUser, id: firebaseUID, firebase_uid: firebaseUID }]);
            await axios.post(`${config.apiHostname}/password-reset-link`, { email: newUser.email });
            setNewUser({
                name: '',
                email: '',
                password: 'temporaryPassword', // Reset the temporary password
                role: 'standard',
                company: '',
                phoneNumber: '',
                customerID: ''
            });
            closeModal(); // Close the modal after adding the user
        } catch (error) {
            logger.error('Error adding user:', error);
        }
    }

    return (
        <div className="form-container">
            <h2>Add New User</h2>
            <form onSubmit={handleAddUser}>
                <div>
                    <label>Name</label>
                    <input
                        type="text"
                        value={newUser.name}
                        onChange={e => setNewUser({ ...newUser, name: e.target.value })}
                        required
                    />
                </div>
                <div>
                    <label>Email</label>
                    <input
                        type="email"
                        value={newUser.email}
                        onChange={e => setNewUser({ ...newUser, email: e.target.value })}
                        required
                    />
                </div>
                <div>
                    <label>Password</label>
                    <input
                        type="password"
                        value={newUser.password}
                        onChange={e => setNewUser({ ...newUser, password: e.target.value })}
                        required
                    />
                </div>
                <div>
                    <label>Role</label>
                    <select
                        value={newUser.role}
                        onChange={e => setNewUser({ ...newUser, role: e.target.value })}
                    >
                        <option value="standard">Standard</option>
                        <option value="admin">Admin</option>
                    </select>
                </div>
                <div>
                    <label>Phone Number</label>
                    <input
                        type="text"
                        value={newUser.phoneNumber}
                        onChange={e => setNewUser({ ...newUser, phoneNumber: e.target.value })}
                    />
                </div>
                <div>
                    <label>Customer</label>
                    <select
                        value={newUser.customerID}
                        onChange={e => setNewUser({ ...newUser, customerID: e.target.value })}
                    >
                        <option value="">Select Customer</option>
                        {customers.map(customer => (
                            <option key={customer.id} value={customer.id}>
                                {customer.name}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="submit">Add User</button>
            </form>
        </div>
    );
}
