import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext'; // Adjust the import path as needed
import CustomerTopics from './CustomerTopics';
import '../../variables.css';
import api from '../../api';
import logger from "../../logger";

export default function Customer() {
    const [customer, setCustomer] = useState(null);
    const { currentXpediteUser } = useAuth();

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await api.get(`/customers/${currentXpediteUser.customerID}`);
                setCustomer(response.data);
            } catch (error) {
                logger.error('Error fetching customer', error);
            }
        };

        if (currentXpediteUser.customerID) {
            fetchCustomer();
        }
    }, [currentXpediteUser.customerID]);

    if (!currentXpediteUser.customerID || !customer) return (
        <div className="loading">
            <div className="spinner"></div>
            <div> Loading </div>
        </div>
    );

    return (
        <div className="container">
            <h1>{customer.name}</h1>
            <div className="row">
                <CustomerTopics customerId={currentXpediteUser.customerID}/>
            </div>
        </div>
    );
}
