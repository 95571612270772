import React from 'react';
import '../../variables.css';

export default function UserList({ users, handleRoleChange, handleDelete, handleEdit }) {
    return (
        <div>
            {users.length === 0 ? (
                <div>No users found.</div>
            ) : (
                <table className="user-table">
                    <thead>
                    <tr>
                        <th>User ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Company</th>
                        <th>Phone Number</th>
                        <th>Customer</th>
                        <th>Change Role</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>{user.company}</td>
                            <td>{user.phoneNumber}</td>
                            <td>{user.customerID}</td>
                            <td>
                                <select
                                    value={user.role}
                                    onChange={e => handleRoleChange(user.id, e.target.value)}
                                >
                                    <option value="standard">Standard</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </td>
                            <td>
                                <button onClick={() => handleEdit(user.id)}>Edit</button>
                                <button onClick={() => handleDelete(user.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}
