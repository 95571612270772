import React, { useRef, useState } from 'react';
import { useAuth } from '../../context/AuthContext'; // Adjust the import path as needed
import { useNavigate, Link } from 'react-router-dom';
import './authentication.css';

export default function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { currentUser, currentXpediteUser, login, error } = useAuth();
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setLoginError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);
            navigate('/');
        } catch (err) {
            setLoginError('Failed to log in');
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <div className="loading">
                <div className="spinner"></div>
                <div>Loading</div>
            </div>
        );
    }

    return (
        <div className="form-container">
            <div className="card">
                <img src="/Xpedite-XLogo-VNameTagline-Red-S.jpg" alt="Kleos Technology Logo" className="form-container-logo" />
                <h2>Login</h2>
                {loginError && <p role="alert" aria-live="assertive">{loginError}</p>}
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" ref={emailRef} required/>
                    </div>
                    <div>
                        <label htmlFor="password">Password</label>
                        <input id="password" type="password" ref={passwordRef} required/>
                    </div>
                    <div>
                        <Link to="/password-reset">Forgot Password?</Link>
                    </div>
                    <button disabled={loading} type="submit">
                        Log In
                    </button>
                </form>
            </div>
        </div>
    );
}
