import React, { useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './authentication.css';
import config from '../../config';

export default function ResetPassword() {
    const { token } = useParams();
    const navigate = useNavigate();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        setError('')

        if (passwordRef.current.value !== confirmPasswordRef.current.value) {
            setError('Passwords do not match');
            setLoading(false);
            return;
        }

        try {
            await axios.post(`${config.apiHostname}/password-reset`, {
                'token': token,
                'password': passwordRef.current.value
            });

            setMessage('Password reset successfully. You can now log in with your new password.');
            setTimeout(() => {
                setError('')
                navigate('/login')
            }, 2000);
        } catch (err) {
            setError('Failed to reset password');
        }
        setLoading(false);
    }

    return (
        <div className="form-container">
            <div className="card">
                <h2>Reset Password</h2>
                {error && <p role="alert" aria-live="assertive">{error}</p>}
                {message && <p role="alert" aria-live="assertive">{message}</p>}
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="password">New Password</label>
                        <input id="password" type="password" ref={passwordRef} required />
                    </div>
                    <div>
                        <label htmlFor="confirmPassword">Confirm New Password</label>
                        <input id="confirmPassword" type="password" ref={confirmPasswordRef} required />
                    </div>
                    <button disabled={loading} type="submit">
                        Reset Password
                    </button>
                </form>
            </div>
        </div>
    );
}
