import React from 'react';

export default function FilterCheckboxes({
                                             showPastDue,
                                             setShowPastDue,
                                             showLessThan5Days,
                                             setShowLessThan5Days,
                                             showLessThan30Days,
                                             setShowLessThan30Days,
                                             showMoreThan30Days,
                                             setShowMoreThan30Days
                                         }) {
    const handleCheckboxChange = (setter, value) => {
        setter(!value);
    };

    return (
        <div className="filter-checkboxes">
            <label className="filter-checkbox">
                <input
                    type="checkbox"
                    checked={showPastDue}
                    onChange={() => handleCheckboxChange(setShowPastDue, showPastDue)}
                />
                <span className="custom-checkbox"></span>
                <span className="checkbox-label">Show past due</span>
            </label>
            <label className="filter-checkbox">
                <input
                    type="checkbox"
                    checked={showLessThan5Days}
                    onChange={() => handleCheckboxChange(setShowLessThan5Days, showLessThan5Days)}
                />
                <span className="custom-checkbox"></span>
                <span className="checkbox-label">Show due within 5 days</span>
            </label>
            <label className="filter-checkbox">
                <input
                    type="checkbox"
                    checked={showLessThan30Days}
                    onChange={() => handleCheckboxChange(setShowLessThan30Days, showLessThan30Days)}
                />
                <span className="custom-checkbox"></span>
                <span className="checkbox-label">Show due within 30 days</span>
            </label>
            <label className="filter-checkbox">
                <input
                    type="checkbox"
                    checked={showMoreThan30Days}
                    onChange={() => handleCheckboxChange(setShowMoreThan30Days, showMoreThan30Days)}
                />
                <span className="custom-checkbox"></span>
                <span className="checkbox-label">Show due greater than 30 days</span>
            </label>
        </div>
    );
}
