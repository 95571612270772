import React from 'react';

export default function Pagination({ currentPage, totalPages, onPageChange }) {
    return (
        <div className="pagination">
            <button onClick={() => onPageChange(1)} disabled={currentPage === 1}>First</button>
            <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
            <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
            <button onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
            <p>Page: {currentPage}/{totalPages}</p>
        </div>
    );
}
