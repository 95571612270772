import React from 'react';
import {getStatusLabel, getUUID} from '../../utils';
import './CustomerTopics.css';

export default function TopicTable({ topics, onSort, onThumbsUp, onThumbsDown, openModal }) {
    return (
        <table className="user-table">
            <thead>
                <tr>
                    <th onClick={() => onSort('TopicNumber')}>Topic Number</th>
                    <th onClick={() => onSort('TopicTitle')}>Topic Title</th>
                    <th onClick={() => onSort('Agency')}>Agency</th>
                    <th onClick={() => onSort('Branch')}>Branch</th>
                    <th onClick={() => onSort('Phase')}>Phase</th>
                    <th onClick={() => onSort('Program')}>Program</th>
                    {/*<th onClick={() => onSort('ReleaseDate')}>Release Date</th>*/}
                    {/*<th onClick={() => onSort('OpenDate')}>Open Date</th>*/}
                    <th onClick={() => onSort('CloseDate')}>Close Date</th>
                    <th onClick={() => onSort('Score')}>Score</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {topics.map(topic =>(
                // const status = topic.status || {text: 'Unknown', style: {}}; // Default fallback
                <tr key={topic.id}>
                    <td onClick={() => openModal(topic)} style={{cursor: 'pointer', textDecoration: 'underline'}}>
                        {topic.topicNumber}
                    </td>
                    <td>{topic.topicTitle}</td>
                    <td>{topic.agency}</td>
                    <td>{topic.branch}</td>
                    <td>{topic.phase}</td>
                    <td>{topic.program}</td>
                    {/*<td>{topic.releaseDate}</td>*/}
                    {/*<td>{topic.openDate}</td>*/}
                    <td>{topic.closeDate}</td>
                    <td>{topic.score.score}</td>
                    <td style={{backgroundColor: getStatusLabel(topic.closeDate).style.backgroundColor,
                        color: getStatusLabel(topic.closeDate).style.color}}
                        className="status-label"
                    >
                        {getStatusLabel(topic.closeDate).text}
                    </td>
                    <td>
                        <button onClick={() => onThumbsUp(topic)}>👍</button>
                        <button onClick={() => onThumbsDown(topic)}>👎</button>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}
