import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useAuth } from '../../context/AuthContext';
import '../../variables.css';
import api from '../../api';
import { debounce } from 'lodash';
import logger from "../../logger";
import FilterCheckboxes from "../customer/FilterCheckboxes";
import FilterInput from "../customer/FilterInput";
import {FilterAndSortTopics, getStatusLabel} from "../../utils";

Modal.setAppElement('#root');

export default function TopicList() {
    const { currentXpediteUser } = useAuth();
    const [topics, setTopics] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
    const [filter, setFilter] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [showPastDue, setShowPastDue] = useState(true);
    const [showLessThan5Days, setShowLessThan5Days] = useState(true);
    const [showLessThan30Days, setShowLessThan30Days] = useState(true);
    const [showMoreThan30Days, setShowMoreThan30Days] = useState(true);

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await api.get(`/topics`);
                setTopics(response.data);
            } catch (error) {
                await logger.error('Error fetching topics', error);
            }
        };

        fetchTopics();
    }, []);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Debounced filter handler
    const handleFilterChange = useCallback(
        debounce((value) => {
            setFilter(value);
        }, 10),
        []
    );

    const handleFilterInputChange = (event) => {
        handleFilterChange(event.target.value);
    };

    const filteredTopics = FilterAndSortTopics(
        topics,
        filter,
        showPastDue,
        showLessThan5Days,
        showLessThan30Days,
        showMoreThan30Days,
        getStatusLabel
    );

    const openModal = (topic) => {
        setSelectedTopic(topic);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedTopic(null);
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        try {
            await api.post(`/upload-topics`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Refresh the topics after successful upload
            const response = await api.get(`/topics`);
            setTopics(response.data);
        } catch (error) {
            logger.error('Error uploading file', error);
        }
    };

    return (
        <div className="container">
            <h2>All Topics ({filteredTopics.length})</h2>
            <div className={`file-upload-container ${currentXpediteUser.role !== 'admin' ? 'centered' : ''}`}>
                {currentXpediteUser.role === 'admin' && (
                    <div className="file-upload">
                        <label htmlFor="file-upload" className="custom-file-upload">
                            Upload Topics
                        </label>
                        <input id="file-upload" type="file" onChange={handleFileUpload}/>
                    </div>
                )}
            </div>
            <FilterInput filter={filter} onFilterChange={handleFilterInputChange}/>
            <FilterCheckboxes
                showPastDue={showPastDue}
                setShowPastDue={setShowPastDue}
                showLessThan5Days={showLessThan5Days}
                setShowLessThan5Days={setShowLessThan5Days}
                showLessThan30Days={showLessThan30Days}
                setShowLessThan30Days={setShowLessThan30Days}
                showMoreThan30Days={showMoreThan30Days}
                setShowMoreThan30Days={setShowMoreThan30Days}
            />
            {filteredTopics.length === 0 ? (
                <p>No topics found.</p>
            ) : (
                <table className="user-table">
                    <thead>
                    <tr>
                        <th onClick={() => handleSort('TopicNumber')}>Topic Number</th>
                        <th onClick={() => handleSort('TopicTitle')}>Topic Title</th>
                        <th onClick={() => handleSort('Agency')}>Agency</th>
                        <th onClick={() => handleSort('Branch')}>Branch</th>
                        <th onClick={() => handleSort('Phase')}>Phase</th>
                        <th onClick={() => handleSort('Program')}>Program</th>
                        <th onClick={() => handleSort('ReleaseDate')}>Release Date</th>
                        <th onClick={() => handleSort('OpenDate')}>Open Date</th>
                        <th onClick={() => handleSort('CloseDate')}>Close Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredTopics.map(topic => {
                        const status = getStatusLabel(topic.closeDate);
                        return (
                            <tr key={topic.ID}>
                                <td onClick={() => openModal(topic)}
                                    style={{cursor: 'pointer', textDecoration: 'underline'}}>
                                    {topic.topicNumber}
                                </td>
                                <td>{topic.topicTitle}</td>
                                <td>{topic.agency}</td>
                                <td>{topic.branch}</td>
                                <td>{topic.phase}</td>
                                <td>{topic.program}</td>
                                <td>{topic.releaseDate}</td>
                                <td>{topic.openDate}</td>
                                <td>{topic.closeDate}</td>
                                <td style={{backgroundColor: status.style.backgroundColor, color: status.style.color}}
                                    className="status-label">
                                    {status.text}
                                </td>
                                <td>
                                    <Link to={`/topic/${topic.topicNumber}`}>View</Link>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            )}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Topic Description"
                className="modal"
                overlayClassName="modal-overlay"
            >
                {selectedTopic && (
                    <>
                        <div style={{...getStatusLabel(selectedTopic.closeDate).style, marginBottom: '1rem'}}
                             className="status-label rounded">
                            {getStatusLabel(selectedTopic.closeDate).text}
                        </div>
                        <h2>Topic Description</h2>
                        <div>
                            <p><strong>Topic Number:</strong> {selectedTopic.topicNumber}</p>
                            <p><strong>Topic Title:</strong> {selectedTopic.topicTitle}</p>
                            <p><strong>Description:</strong> {selectedTopic.description}</p>
                            <p><strong>Agency:</strong> {selectedTopic.agency}</p>
                            <p><strong>Branch:</strong> {selectedTopic.branch}</p>
                            <p><strong>Phase:</strong> {selectedTopic.phase}</p>
                            <p><strong>Program:</strong> {selectedTopic.program}</p>
                            <p><strong>Release Date:</strong> {selectedTopic.releaseDate}</p>
                            <p><strong>Open Date:</strong> {selectedTopic.openDate}</p>
                            <p><strong>Close Date:</strong> {selectedTopic.closeDate}</p>
                        </div>
                    </>
                )}
                <button onClick={closeModal}>Close</button>
            </Modal>
        </div>
    );
}
