import { useState } from 'react';

export default function useSort() {
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { sortConfig, handleSort };
}
