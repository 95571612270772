import axios from "axios";
import config from "./config";

async function logToServer(message, level) {
    await axios.post(`${config.apiHostname}/log`, {
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({message, level})
    });
}

const logger = {
    info: async (message) => {
        await logToServer(message, 'info');
    },
    error: async (message) => {
        await logToServer(message, 'error');
    },
    warn: async (message) => {
        await logToServer(message, 'warn');
    }
};

export default logger;