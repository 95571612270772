import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useMemo } from 'react';
import {marked} from 'marked';
import DOMPurify from 'dompurify';

export function downloadFile(data, fileName, mimeType) {
    const blob = new Blob([data], { type: mimeType });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function exportToCSV(filteredTopics) {
    const csv = Papa.unparse(filteredTopics);
    downloadFile(csv, 'customer_topics.csv', 'text/csv;charset=utf-8;');
}

export function exportToTSV(filteredTopics) {
    const tsv = Papa.unparse(filteredTopics, { delimiter: '\t' });
    downloadFile(tsv, 'customer_topics.tsv', 'text/tsv;charset=utf-8;');
}

export function exportToExcel(filteredTopics) {
    const worksheet = XLSX.utils.json_to_sheet(filteredTopics);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Topics');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    downloadFile(blob, 'customer_topics.xlsx', 'application/octet-stream');
}

export function getStatusLabel(closeDate) {
    const today = new Date();
    const close = new Date(closeDate);
    const diffTime = close - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
        return { text: 'Past Due', style: { backgroundColor: 'black', color: 'white' } };
    }
    if (diffDays < 5) {
        return { text: '<5 Days', style: { backgroundColor: 'red', color: 'white' } };
    }
    if (diffDays < 30) {
        return { text: '<30 Days', style: { backgroundColor: 'yellow', color: 'black' } };
    }
    return { text: '>30 Days', style: { backgroundColor: 'green', color: 'black' } };
};

export const FilterAndSortTopics = (topics, filter, showPastDue, showLessThan5Days, showLessThan30Days, showMoreThan30Days, getStatusLabel) => {
    // Function to filter topics based on checkbox states
    const updateFilteredTopics = () => {
        return topics.filter(topic => {
            const status = getStatusLabel(topic.closeDate).text;

            const statusFilterMatch = (
                (showPastDue || status !== 'Past Due') &&
                (showLessThan5Days || status !== '<5 Days') &&
                (showLessThan30Days || status !== '<30 Days') &&
                (showMoreThan30Days || status !== '>30 Days')
            );

            return statusFilterMatch && (
                topic.topicNumber.toLowerCase().includes(filter.toLowerCase()) ||
                topic.topicTitle.toLowerCase().includes(filter.toLowerCase()) ||
                topic.agency.toLowerCase().includes(filter.toLowerCase()) ||
                topic.branch.toLowerCase().includes(filter.toLowerCase()) ||
                topic.program.toLowerCase().includes(filter.toLowerCase())
            );
        });
    };

    return useMemo(() => updateFilteredTopics(), [topics, filter, showPastDue, showLessThan5Days, showLessThan30Days, showMoreThan30Days]);
};

export const FilterAndSortOpportunities = (
    opportunities,
    filter,
    showPastDue,
    showLessThan5Days,
    showLessThan30Days,
    showMoreThan30Days,
    getStatusLabel,
    fromDate,  // New parameter for the "from" date
    toDate     // New parameter for the "to" date
) => {
    // Function to filter opportunities based on checkbox states, search filters, and date range
    const updateFilteredOpportunities = () => {
        return opportunities.filter(opportunity => {
            const status = getStatusLabel(opportunity.responseDeadLine).text;

            // Check status filter matches
            const statusFilterMatch = (
                (showPastDue || status !== 'Past Due') &&
                (showLessThan5Days || status !== '<5 Days') &&
                (showLessThan30Days || status !== '<30 Days') &&
                (showMoreThan30Days || status !== '>30 Days')
            );

            // Convert opportunity.postedDate to a Date object for comparison
            const postedDate = new Date(opportunity.postedDate);

            // Check date range filtering (if fromDate or toDate is provided)
            const fromDateMatch = fromDate ? new Date(fromDate) <= postedDate : true;
            const toDateMatch = toDate ? new Date(toDate) >= postedDate : true;

            // Check text search filter matches
            const textFilterMatch = (
                opportunity.noticeId.toLowerCase().includes(filter.toLowerCase()) ||
                opportunity.title.toLowerCase().includes(filter.toLowerCase()) ||
                (opportunity.solicitationNumber && opportunity.solicitationNumber.toLowerCase().includes(filter.toLowerCase())) ||
                (opportunity.department && opportunity.department.toLowerCase().includes(filter.toLowerCase())) ||
                (opportunity.subTier && opportunity.subTier.toLowerCase().includes(filter.toLowerCase())) ||
                (opportunity.office && opportunity.office.toLowerCase().includes(filter.toLowerCase())) ||
                (opportunity.naicsCode && opportunity.naicsCode.toLowerCase().includes(filter.toLowerCase())) ||
                (opportunity.descriptionText && opportunity.descriptionText.toLowerCase().includes(filter.toLowerCase())) ||
                (opportunity.classificationCode && opportunity.classificationCode.toLowerCase().includes(filter.toLowerCase()))
            );

            // Final match condition
            return statusFilterMatch && textFilterMatch && fromDateMatch && toDateMatch;
        });
    };

    return useMemo(() => updateFilteredOpportunities(), [
        opportunities,
        filter,
        showPastDue,
        showLessThan5Days,
        showLessThan30Days,
        showMoreThan30Days,
        fromDate,   // Add fromDate as a dependency
        toDate      // Add toDate as a dependency
    ]);
};


export function parseExplanation(explanation, thumbsUp = true) {
    // Convert markdown to HTML
    const htmlContent = marked(explanation);

    // Define the regex patterns for positive and negative elements
    const positivePattern = /<h2[^>]*>Positive elements<\/h2>(.*?)<h2[^>]*>/s;
    const negativePattern = /<h2[^>]*>Negative elements<\/h2>(.*?)<h2[^>]*>/s;

    let reasonsArray = [];

    // Extract the relevant section based on thumbsUp flag
    const pattern = thumbsUp ? positivePattern : negativePattern;
    const match = htmlContent.match(pattern);
    console.log(explanation)

    if (match && match[1]) {
        // Split the matched content by list items
        const listItems = match[1].match(/<li[^>]*>(.*?)<\/li>/g);

        if (listItems) {
            // Extract text from each list item and push to reasonsArray
            reasonsArray = listItems.map(item => {
                const listItemMatch = item.match(/<li[^>]*>(.*?)<\/li>/);
                return listItemMatch ? listItemMatch[1].trim() : '';
            });
        }
    }

    return reasonsArray;
}

export function decodeHtml(html) {
    const sanitizedHtml = DOMPurify.sanitize(html); // Sanitize the HTML
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}