import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useAuth } from '../../context/AuthContext';
import '../../variables.css';
import { debounce } from 'lodash';
import FilterCheckboxes from "../customer/FilterCheckboxes";
import FilterInput from "../customer/FilterInput";
import { getStatusLabel, FilterAndSortOpportunities, decodeHtml } from "../../utils";
import Pagination from "../customer/Pagination";
import useFetchOpportunities from '../../hooks/useFetchOpportunities';

export default function SAMOpportunitiesList() {
    const { currentXpediteUser } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10); // Number of records per page
    const { opportunities, loading, error, totalRecords } = useFetchOpportunities(0, 0);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
    const [filter, setFilter] = useState('');
    const [fromDate, setFromDate] = useState(''); // From date for filtering
    const [toDate, setToDate] = useState(''); // To date for filtering
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);
    const [showPastDue, setShowPastDue] = useState(true);
    const [showLessThan5Days, setShowLessThan5Days] = useState(true);
    const [showLessThan30Days, setShowLessThan30Days] = useState(true);
    const [showMoreThan30Days, setShowMoreThan30Days] = useState(true);

    // Sorting logic
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Debounced filter change handler
    const handleFilterChange = useCallback(
        debounce((value) => {
            setFilter(value);
            setCurrentPage(1); // Reset to page 1 whenever filter changes
        }, 10), // Debounce to avoid too many updates
        []
    );

    const handleFilterInputChange = (event) => {
        handleFilterChange(event.target.value);
    };

    // Handle date change for filtering
    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    // Get filtered opportunities with date filtering
    const filteredOpportunities = FilterAndSortOpportunities(
        opportunities,
        filter,
        showPastDue,
        showLessThan5Days,
        showLessThan30Days,
        showMoreThan30Days,
        getStatusLabel,
        fromDate,
        toDate // Pass date filters to the utility function
    );

    // Calculate total pages for filtered opportunities
    const totalFilteredPages = Math.ceil(Math.min(filteredOpportunities.length, totalRecords) / limit);

    // Slice filtered opportunities for current page
    const paginatedOpportunities = filteredOpportunities.slice(
        (currentPage - 1) * limit,
        currentPage * limit
    );

    const openModal = (opportunity) => {
        setSelectedOpportunity(opportunity);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedOpportunity(null);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="container">
            <h2>All Opportunities ({filteredOpportunities.length})</h2>

            <div className="filter-section">
                <FilterInput filter={filter} onFilterChange={handleFilterInputChange} />
                <FilterCheckboxes
                    showPastDue={showPastDue}
                    setShowPastDue={setShowPastDue}
                    showLessThan5Days={showLessThan5Days}
                    setShowLessThan5Days={setShowLessThan5Days}
                    showLessThan30Days={showLessThan30Days}
                    setShowLessThan30Days={setShowLessThan30Days}
                    showMoreThan30Days={showMoreThan30Days}
                    setShowMoreThan30Days={setShowMoreThan30Days}
                />
                <div className="date-filter-container">
                    <label className="date-filter">
                        From:
                        <input type="date" value={fromDate} onChange={handleFromDateChange} />
                    </label>
                    <label className="date-filter">
                        To:
                        <input type="date" value={toDate} onChange={handleToDateChange} />
                    </label>
                </div>
            </div>

            {paginatedOpportunities.length === 0 && !loading ? (
                <p>No opportunities found.</p>
            ) : (
                <table className="user-table">
                    <thead>
                    <tr>
                        <th onClick={() => handleSort('noticeId')}>Notice ID</th>
                        <th onClick={() => handleSort('title')}>Title</th>
                        <th onClick={() => handleSort('department')}>Department</th>
                        <th onClick={() => handleSort('postedDate')}>Posted Date</th>
                        <th onClick={() => handleSort('responseDeadLine')}>Close Date</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedOpportunities.map((opportunity) => {
                        const status = getStatusLabel(opportunity.responseDeadLine);
                        return (
                            <tr key={opportunity.noticeId}>
                                <td onClick={() => openModal(opportunity)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                    {opportunity.noticeId}
                                </td>
                                <td>{opportunity.title}</td>
                                <td>{opportunity.department || 'N/A'}</td>
                                <td>{opportunity.postedDate}</td>
                                <td>{opportunity.responseDeadLine}</td>
                                <td style={{ backgroundColor: status.style.backgroundColor, color: status.style.color }} className="status-label">
                                    {status.text}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            )}

            <Pagination
                currentPage={currentPage}
                totalPages={totalFilteredPages}
                onPageChange={handlePageChange} // Fetch new data on page change
            />

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Opportunity Description" className="modal" overlayClassName="modal-overlay">
                {selectedOpportunity && (
                    <>
                        <div style={{ ...getStatusLabel(selectedOpportunity.responseDeadLine).style, marginBottom: '1rem' }} className="status-label rounded">
                            {getStatusLabel(selectedOpportunity.responseDeadLine).text}
                        </div>
                        <h2>Opportunity Description</h2>
                        <div>
                            <p><strong>Notice ID:</strong> {selectedOpportunity.noticeId}</p>
                            <p><strong>Title:</strong> {selectedOpportunity.title}</p>
                            <p><strong>Description:</strong> {decodeHtml(selectedOpportunity.descriptionText)}</p>
                            <p><strong>Department:</strong> {selectedOpportunity.department}</p>
                            <p><strong>Posted Date:</strong> {selectedOpportunity.postedDate}</p>
                            <p><strong>Close Date:</strong> {selectedOpportunity.responseDeadLine}</p>
                        </div>
                    </>
                )}
                <button onClick={closeModal}>Close</button>
            </Modal>
        </div>
    );
}
