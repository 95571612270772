import React from 'react';

export default function FilterInput({ filter, onFilterChange }) {
    return (
        <input
            type="text"
            placeholder="Filter topics..."
            value={filter}
            onChange={onFilterChange}
            className="filter-input"
        />
    );
}
