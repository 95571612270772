import { useState, useEffect, useRef } from 'react';
import api from '../api';
import logger from "../logger";

const useFetchOpportunities = (page, limit) => {
    const [opportunities, setOpportunities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const hasFetched = useRef(false); // To avoid duplicate calls

    useEffect(() => {
        // Only fetch opportunities if it hasn't been fetched already
        if (hasFetched.current) {
            return;
        }

        const fetchOpportunities = async () => {
            setLoading(true);
            setError(null);
            try {
                const offset = (page - 1) * limit;
                const response = await api.get(`/opportunities?offset=${offset}&limit=${limit}`);
                const data = response.data.opportunitiesData;
                setOpportunities(prev => [...prev, ...data]); // Append new opportunities to the existing list
                setTotalRecords(response.data.totalRecords || 0); // Set the total number of records if the API provides it
            } catch (err) {
                setError('Error fetching opportunities');
                logger.error('Error fetching opportunities', err);
            } finally {
                setLoading(false);
                hasFetched.current = true; // Ensure it doesn't fetch again
            }
        };

        fetchOpportunities();

        // Reset the `hasFetched` flag whenever the page changes
        return () => {
            hasFetched.current = false;
        };
    }, [page, limit]);

    return { opportunities, loading, error, totalRecords, setOpportunities };
};

export default useFetchOpportunities;
