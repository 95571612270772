import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateCustomer from './CreateCustomer';
import '../../variables.css';
import Modal from 'react-modal';
import api from '../../api';
import logger from "../../logger";

export default function CustomerList() {
    const [customers, setCustomers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [confirmationAction, setConfirmationAction] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/customers`);
                setCustomers(response.data);
                setIsLoading(false);
            } catch (error) {
                logger.error('Error fetching customer', error);
            }
        };
        fetchCustomers();
    }, [isModalOpen]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleView = (id) => {
        navigate(`/customer/${id}`);
    };

    const handleDelete = async (id) => {
        try {
            await api.delete(`/customers/${id}`);
            setCustomers(customers.filter(customer => customer.id !== id));
        } catch (error) {
            logger.error('Error deleting customer', error);
        }
    };

    const openConfirmModal = (action, message) => {
        setConfirmationAction(() => action);
        setConfirmationMessage(message);
        setIsConfirmModalOpen(true);
    };

    const closeConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setConfirmationAction(null);
        setConfirmationMessage('');
    };

    const handleConfirmAction = () => {
        if (confirmationAction) {
            confirmationAction(); // Execute the stored action
        }
        closeConfirmModal();
    };

    const confirmDelete = (id) => {
        openConfirmModal(
            () => handleDelete(id),
            'Are you sure you want to delete this customer?'
        );
    };

    if (isLoading) return (
        <div className="loading">
            <div className="spinner"></div>
            Loading
        </div>
    );

    return (
        <div className="admin-container">
            <h2>All Customers</h2>
            <button onClick={openModal}>Create Customer</button>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Create Customer"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <CreateCustomer closeModal={closeModal} />
            </Modal>
            {customers.length === 0 ? (
                <p>No customers found.</p>
            ) : (
                <table className="customer-table">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Billing</th>
                        <th>Plan</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {customers.map(customer => (
                        <tr key={customer.id}>
                            <td>{customer.id}</td>
                            <td>{customer.name}</td>
                            <td>{customer.status.currentState}</td>
                            <td>{customer.status.billing.state}</td>
                            <td>{customer.status.billing.plan}</td>
                            <td>
                                <button onClick={() => handleView(customer.id)}>View</button>
                                <button onClick={() => confirmDelete(customer.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
            <Modal
                isOpen={isConfirmModalOpen}
                onRequestClose={closeConfirmModal}
                contentLabel="Confirm Action"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <h2>Are you sure?</h2>
                <p>{confirmationMessage}</p>
                <button onClick={handleConfirmAction}>Yes</button>
                <button onClick={closeConfirmModal}>No</button>
            </Modal>
        </div>
    );
}
