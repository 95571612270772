import React, { useRef, useState } from 'react';
import api from '../../api';
import logger from "../../logger";

export default function CreateCustomer({ closeModal }) {
    const nameRef = useRef();
    const [profile, setProfile] = useState('');

    async function handleSubmit(e) {
        e.preventDefault();

        const newCustomer = {
            id: 0,
            name: nameRef.current.value,
            profile: profile
        };

        try {
            await api.post(`/customers`, newCustomer);
            closeModal();
        } catch (error) {
            logger.error('Error creating customer', error);
        }
    }

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = function(e) {
            setProfile(e.target.result);
        };
        reader.readAsText(file);
    };

    return (
        <div className="admin-container">
            <h2>Create Customer</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <input type="text" ref={nameRef} required placeholder="Name" />
                </div>
                <div>
                    <textarea
                        value={profile}
                        onChange={(e) => setProfile(e.target.value)}
                        placeholder="Profile"
                        rows="10"
                        cols="50"
                    />
                </div>
                <div className="file-upload">
                    <label htmlFor="file-upload" className="custom-file-upload">
                        Upload Profile
                    </label>
                    <input id="file-upload" type="file" onChange={handleFileUpload} />
                </div>
                <button type="submit">Create</button>
            </form>
        </div>
    );
}
