import { useState, useCallback } from 'react';
import { debounce } from 'lodash';

export default function useFilter() {
    const [filter, setFilter] = useState('');

    const handleFilterChange = useCallback(
        debounce((value) => {
            setFilter(value);
        }, 10),
        []
    );

    return { filter, handleFilterChange };
}
