import React, { useMemo, useState } from 'react';
import axios from 'axios';
import ErrorBoundary from '../../ErrorBoundary';
import FilterInput from './FilterInput';
import TopicTable from './TopicTable';
import Pagination from './Pagination';
import TopicModal from './TopicModal';
import useFetchTopics from '../../hooks/useFetchTopics';
import useSort from '../../hooks/useSort';
import useFilter from '../../hooks/useFilter';
import {
    exportToCSV,
    exportToTSV,
    exportToExcel,
    getStatusLabel,
    FilterAndSortTopics,
    parseExplanation
} from '../../utils';
import FilterCheckboxes from "./FilterCheckboxes";
import ReasonModal from './ReasonModal';
import api from "../../api";

export default function CustomerTopics({ customerId }) {
    const { topics, setTopics, error } = useFetchTopics(customerId);
    const { sortConfig, handleSort } = useSort();
    const { filter, handleFilterChange } = useFilter();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState(null); // 'topic' or 'reason'
    const [reasonModalOpen, setReasonModalOpen] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showPastDue, setShowPastDue] = useState(true);
    const [showLessThan5Days, setShowLessThan5Days] = useState(true);
    const [showLessThan30Days, setShowLessThan30Days] = useState(true);
    const [showMoreThan30Days, setShowMoreThan30Days] = useState(true);
    const [temperature, setTemperature] = useState('');
    const [reasonsArray, setReasonsArray] = useState([]);
    const itemsPerPage = 10;

    const filteredTopics = FilterAndSortTopics(
        topics,
        filter,
        showPastDue,
        showLessThan5Days,
        showLessThan30Days,
        showMoreThan30Days,
        getStatusLabel
    );

    const openTopicModal = (topic) => {
        console.log('Opening Topic Modal');
        setSelectedTopic(topic);
        setModalType('topic');
    };

    const openReasonModal = (topic, status) => {
        console.log('Opening Reason Modal', status);
        setSelectedTopic(topic);
        setSelectedStatus(status);
        setReasonsArray(parseExplanation(topic.score.explanation, status === "Thumbs up"));
        setModalType('reason');
    };

    const closeModal = () => {
        setModalType(null);
        setSelectedTopic(null);
        setSelectedStatus('');
    };

    const handleReasonSubmit = async (status, reasonData) => {
        try {
            // Map reasonData to match the Go struct
            const formattedReasons = reasonData.map(reason => ({
                temperature: selectedStatus,
                reason: reason,
            }));

            // Construct the request payload
            const payload = {
                customerID: Number(customerId),
                topicID: selectedTopic.id,
                topicTitle: selectedTopic.topicNumber,
                status: selectedStatus,
                reasons: formattedReasons,
            };

            // Send the request
            await api.put('/interest', payload);

            console.log(`Customer interest updated to ${selectedStatus}`);
        } catch (error) {
            console.error('Error updating customer interest:', error);
        } finally {
            closeModal();
        }
    };

    const handleThumbsUp = (topic) => {
        openReasonModal(topic, "Thumbs up");
    };

    const handleThumbsDown = (topic) => {
        openReasonModal(topic, "Thumbs down");
    };

    const totalPages = Math.ceil(filteredTopics.length / itemsPerPage);
    const currentItems = filteredTopics.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <ErrorBoundary>
            <div className="topics-card">
                {error ? (
                    <p className="error-message">{error}</p>
                ) : (
                    <>
                        <h2>Topics ({filteredTopics.length})</h2>
                        <FilterInput filter={filter} onFilterChange={e => handleFilterChange(e.target.value)} />
                        <FilterCheckboxes
                            showPastDue={showPastDue}
                            setShowPastDue={setShowPastDue}
                            showLessThan5Days={showLessThan5Days}
                            setShowLessThan5Days={setShowLessThan5Days}
                            showLessThan30Days={showLessThan30Days}
                            setShowLessThan30Days={setShowLessThan30Days}
                            showMoreThan30Days={showMoreThan30Days}
                            setShowMoreThan30Days={setShowMoreThan30Days}
                        />
                        <div className="export-container">
                            <button className="export-button" onClick={() => exportToCSV(filteredTopics)}>Export as CSV</button>
                            <button className="export-button" onClick={() => exportToTSV(filteredTopics)}>Export as TSV</button>
                            <button className="export-button" onClick={() => exportToExcel(filteredTopics)}>Export as Excel</button>
                        </div>
                        <TopicTable
                            topics={currentItems}
                            onSort={handleSort}
                            onThumbsUp={handleThumbsUp}
                            onThumbsDown={handleThumbsDown}
                            openModal={openTopicModal}
                        />
                        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                        {modalType === 'topic' && (
                            <TopicModal
                                isOpen={modalType === 'topic'}
                                onRequestClose={closeModal}
                                topic={selectedTopic}
                                getStatusLabel={getStatusLabel}
                            />
                        )}

                        {modalType === 'reason' && (
                            <ReasonModal
                                isOpen={modalType === 'reason'}
                                onRequestClose={closeModal}
                                onSubmit={handleReasonSubmit}
                                status={selectedStatus}
                                temperature={temperature}
                                reasonsArray={reasonsArray}
                            />
                        )}
                    </>
                )}
            </div>
        </ErrorBoundary>
    );
}
