import React, { useRef, useState } from 'react';
import axios from 'axios';
import './authentication.css';
import api from '../../api';
import config from "../../config";

export default function PasswordReset() {
    const emailRef = useRef();
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        setError('');
        e.preventDefault();
        setLoading(true);

        try {
            const email = emailRef.current.value;
            // Replace the following line with the actual API call to request a password reset
            await axios.post(`${config.apiHostname}/password-reset-link`, { email });
            setMessage('Check your email for further instructions');
        } catch {
            setError('Failed to reset password');
        }

        setLoading(false);
    }

    return (
        <div className="form-container">
            <div className="card">
                <h2>Request Password Reset</h2>
                {message && <p role="alert" aria-live="assertive">{message}</p>}
                {error && <p role="alert" aria-live="assertive">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" ref={emailRef} required/>
                    </div>
                    <button disabled={loading} type="submit">
                        Reset Password
                    </button>
                </form>
            </div>
        </div>
    );
}
