// src/context/AuthContext.js
import React, {useContext, useEffect, useState} from 'react';
import {auth} from '../firebase';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import api from '../api';
import config from '../config';
import axios from "axios";
import {jwtDecode} from 'jwt-decode';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState({});
    const [currentXpediteUser, setCurrentXpediteUser] = useState({
        role: 'none',
        customerID: '0',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    async function fetchUserRole(user) {
        if (user) {
            try {
                const response = await api.get(`/users/${user.uid}`);
                setCurrentXpediteUser(response.data);
            } catch (error) {
                setError('Failed to fetch user role');
            }
        }
    }

    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    async function login(email, password) {
        try {
            const { user } = await signInWithEmailAndPassword(auth, email, password);
            const idToken = await user.getIdToken();
            if (idToken) {
                const response = await axios.post(`${config.apiHostname}/login`, { token: idToken });
                localStorage.setItem('jwt', response.data.jwt);
                const decoded = jwtDecode(response.data.jwt);
                // setAuthToken(response.data.jwt);
                setCurrentUser(decoded);
                await fetchUserRole(decoded);
            }
        } catch (error) {
            setError('Failed to log in');
            throw error;
        }
    }

    function logout() {
        signOut(auth)
        // setError('');
        setCurrentUser(null);
        setCurrentXpediteUser(null);
        localStorage.removeItem('jwt');
    }

    useEffect(() => {
        return auth.onAuthStateChanged(async user => {
            if (user) {
                await fetchUserRole(user);
            }
            setCurrentUser(user);
            setError('')
            setLoading(false);
        });
    }, []);

    const value = {
        currentUser,
        currentXpediteUser,
        signup,
        login,
        logout,
        error,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
