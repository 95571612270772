import React, { useState, useEffect } from 'react';
import api from '../../api';
import logger from '../../logger';

export default function JobsList() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await api.get('/jobs');
                logger.info('Jobs loaded successfully');
                // sort jobs
                response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setJobs(response.data);
                setLoading(false);
            } catch (error) {
                logger.error('Failed to load jobs');
                setError('Failed to load jobs');
                setLoading(false);
            }
        };

        fetchJobs();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="container">
            <h1>Jobs List</h1>
            <table className="data-table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Job</th>
                    <th>Customer ID</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Completed At</th>
                </tr>
                </thead>
                <tbody>
                {jobs.map((job) => (
                    <tr key={job.id}>
                        <td>{job.id}</td>
                        <td>{job.job_type}</td>
                        <td>{job.customer}</td>
                        <td>{job.status}</td>
                        <td>{new Date(job.created_at).toLocaleString()}</td>
                        <td>{new Date(job.updated_at).toLocaleString()}</td>
                        <td>{job.completed_at ? new Date(job.completed_at).toLocaleString() : 'N/A'}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}
