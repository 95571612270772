import React from 'react';
import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import {decodeHtml} from "../../utils";

export default function TopicModal({ isOpen, onRequestClose, topic, getStatusLabel }) {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Topic Description"
            className="modal"
            overlayClassName="modal-overlay"
        >
            {topic && (
                <>
                    <div style={{ ...getStatusLabel(topic.closeDate).style, marginBottom: '1rem' }} className="status-label rounded">
                        {getStatusLabel(topic.closeDate).text}
                    </div>
                    <h2>Topic Info</h2>
                    <div>
                        <p><strong>Topic Number:</strong> {topic.topicNumber}</p>
                        <p><strong>Topic Title:</strong> {topic.topicTitle}</p>
                        <p><strong>Agency:</strong> {topic.agency}</p>
                        <p><strong>Branch:</strong> {topic.branch}</p>
                        <p><strong>Phase:</strong> {topic.phase}</p>
                        <p><strong>Program:</strong> {topic.program}</p>
                        <p><strong>Release Date:</strong> {topic.releaseDate}</p>
                        <p><strong>Open Date:</strong> {topic.openDate}</p>
                        <p><strong>Close Date:</strong> {topic.closeDate}</p>
                    </div>
                    <h2>Xpedite Analysis</h2>
                    <div>
                        <p><strong>Score:</strong> {topic.score.score}</p>
                        <p><strong>Xplanation:</strong></p>
                        <ReactMarkdown>{topic.score.explanation}</ReactMarkdown>
                    </div>
                    <h2>Topic Description</h2>
                    <div>
                        <p><strong>Description:</strong> {decodeHtml(topic.description)}</p>
                    </div>
                </>
            )}
            <button onClick={onRequestClose}>Close</button>
        </Modal>
    );
}
