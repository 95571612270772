import React from 'react';
import './Avatar.css';

const Avatar = ({ name }) => {
    return (
        <div className="avatar-container">
            <div className="avatar-icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    viewBox="0 0 24 24">
                    <circle cx="12" cy="7" r="4"></circle>
                    <path d="M5.5 20.5c.43-1.71 1.72-3.19 3.5-4.04 1.78-.85 3.86-.85 5.64 0 1.78.85 3.07 2.33 3.5 4.04"></path>
                </svg>
            </div>
            <div className="avatar-name">{name}</div>
        </div>
    );
};

export default Avatar;
