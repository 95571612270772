import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Necessary for Chart.js v3+
import './PriorArt.css';
import api from '../../api';
import { useAuth } from '../../context/AuthContext';
import logger from "../../logger";

export default function PriorArt() {
    const [priorArt, setPriorArt] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedCustomerID, setSelectedCustomerID] = useState('');
    const [customers, setCustomers] = useState([]);
    const { currentXpediteUser } = useAuth();

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await api.get('/customers');
                setCustomers(response.data);
            } catch (error) {
                logger.error('Error fetching customers', error);
                setError('Failed to load customers');
            }
        };

        if (currentXpediteUser.role === 'admin') {
            fetchCustomers();
            fetchPriorArt();
        }
    }, [currentXpediteUser.role]);

    const fetchPriorArt = async (customerID) => {
        setLoading(true);
        try {
            const response = await api.get(`/customers/${customerID || currentXpediteUser.customerID}/sbirdata`, {
                params: { page, limit }
            });
            setPriorArt(response.data.data);
            setTotalRecords(response.data.totalRecords);
            setLoading(false);
        } catch (error) {
            logger.error('Error fetching prior art', error);
            setError('Failed to load data');
            setLoading(false);
        }
    };

    useEffect(() => {
        // fetchPriorArt();
    }, [page, limit, selectedCustomerID]);

    const handleNextPage = () => {
        if (page * limit < totalRecords) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleCustomerSelect = (event) => {
        setSelectedCustomerID(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchPriorArt(selectedCustomerID);
    };

    const aggregateDataByYear = (data) => {
        const aggregatedData = data.reduce((acc, curr) => {
            const year = curr.awardYear || 'Unknown';
            let amount = 0
            if (curr.awardAmount)
                amount = parseFloat(curr.awardAmount.replace(/,/g, '')) || 0; // Remove commas before parsing
            if (!acc[year]) {
                acc[year] = 0;
            }
            acc[year] += amount;
            return acc;
        }, {});

        const labels = Object.keys(aggregatedData);
        const values = Object.values(aggregatedData);

        return { labels, values };
    };

    const { labels, values } = aggregateDataByYear(priorArt);

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Award Amounts',
                data: values,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: (value) => {
                        return `$${value.toLocaleString()}`;
                    }
                },
                suggestedMax: Math.max(...values) * 1.1 // Add 10% padding to the highest value
            },
        },
    };

    if (loading) {
        return (
            <div className="loading">
                <div className="spinner"></div>
                <div>Loading</div>
            </div>
        );
    }
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="container">
            <h1>Awards</h1>
            {currentXpediteUser.role === 'admin' && (
                <div className="customer-search">
                    <form onSubmit={handleSearch}>
                        <select value={selectedCustomerID} onChange={handleCustomerSelect}>
                            <option value="">Select Customer</option>
                            {customers.map(customer => (
                                <option key={customer.id} value={customer.id}>{customer.name}</option>
                            ))}
                        </select>
                        <button type="submit">Search</button>
                    </form>
                </div>
            )}
            <table className="data-table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Company</th>
                    <th>Award Title</th>
                    <th>Agency</th>
                    <th>Phase</th>
                    <th>Program</th>
                    <th>Contract</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                {priorArt.map((art) => (
                    <tr key={art.id}>
                        <td>{art.id}</td>
                        <td>{art.company}</td>
                        <td>{art.awardTitle}</td>
                        <td>{art.agency}</td>
                        <td>{art.phase}</td>
                        <td>{art.program}</td>
                        <td>{art.contract}</td>
                        <td>${art.awardAmount}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={page === 1}>
                    Previous
                </button>
                <button onClick={handleNextPage} disabled={page * limit >= totalRecords}>
                    Next
                </button>
            </div>
            <div className="chart-container">
                <h2>Award Amounts Distribution</h2>
                <Bar data={chartData} options={chartOptions} />
            </div>
        </div>
    );
}
