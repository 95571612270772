import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomerTopics from '../customer/CustomerTopics';
import { useParams } from 'react-router-dom';
import '../../variables.css';
import config from '../../config';
import api from '../../api';
import logger from "../../logger";

export default function Customer() {
    const [customer, setCustomer] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await api.get(`/customers/${id}`);
                setCustomer(response.data);
            } catch (error) {
                await logger.error('Error fetching customer', error);
            }
        };

        fetchCustomer();
    }, [id]);

    if (!customer) return (
            <div className="loading">
                <div className="spinner"></div>
                Loading
            </div>
        );

    return (
        <div className="container">
            <h1>{customer.name}</h1>
            <div className="row">
                <CustomerTopics customerId={id}/>
            </div>
        </div>
    );
}
