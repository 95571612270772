import { useState, useEffect } from 'react';
import api from '../api';
import logger from "../logger";

export default function useFetchTopics(customerId) {
    const [topics, setTopics] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await api.get(`/customers/${customerId}/topics`);
                let topics = response.data
                // modify the topics array so that each topic gets a uuid -> getUUID()
                setTopics(topics)
            } catch (error) {
                await logger.error('Error fetching topics', error);
                setError('Failed to load topics');
            }
        };

        fetchTopics();
    }, [customerId]);

    return { topics, setTopics, error };
}
